.scrollableDiv {
    overflow-y: scroll;
    max-height: 250px;
}

.ContactManagementPanel .content {
    margin: 10px 10px 0px 10px;
}

.ContactManagementPanel .btn-toolbar {
    padding-bottom: 10px; 
}

/* table {
    border-collapse: collapse;
    overflow-x: scroll;
    display: block;
}
  
thead {
    text-align: center;
    background-color: #EFEFEF;
    /* display: block;
}
  
tbody {
    text-align: center;
    overflow-y: scroll;
    overflow-x: scroll;
    height: 140px;
    width: 200px;
    /* display: block;
}
  
td,th {
    text-align:center;
    height: 25px;
    border: dashed 1px lightblue;
    overflow: hidden;
    /* white-space: nowrap;
} */