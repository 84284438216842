.searchForm {
    margin: 0 auto;
    max-width: 350px;
}
.summaryTable {
    margin: 0 auto;
    max-width: 450px;
}

Form {
    padding: 10px;
}

.inlineForm {
    padding-right: 10px;
}