@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 320px;
    }

    .textCentred {
      text-align: center;
      padding-bottom: 20px;
      color: blue;
    }

    .passwordReset {
      margin: 20px auto;
      max-width: 320px;
    }
  }