@media all and (min-width: 480px) {
  
    .SiteUserPanel form {
      margin: 0px auto;
      max-width: 100%;
    }
  }
  
  .flexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flexRow2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 10px 10px 10px;
  }

  .modalFlexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .modalFlexCol {
    display: flex;
    flex-direction: column;
  }

  .flexcol {
    display: flex;
    flex-direction: column;
    margin: 25px 20px 0px 20px;
  }

  .imageflexrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  
  .btnSmall {
    margin: 0px 5px 0px 10px;
  }
  
  .SiteUserPanel .frmPanel {
    margin: 10px 10px 0px 10px;
  }

  .modalImage {
    height: auto;
    width: auto;
    max-height: 550px;
  }

  .modalDialog {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heavyText {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
  }

  .flexRight {
    display: flex;
    flex-direction: row;
    float: right;
  }

  .notesHeader {
    font-family: "Open Sans", sans-serif;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 550px;
  }

  .notes {
    font-family: "Open Sans", sans-serif;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 550px;
  }

  .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }