body {
  background-color: rgb(245, 246, 250);
}

.App {
  padding-top: 15px;
  background-color: white;
  box-shadow: 0 0 13px -4px rgba(9,30,66,.25);
  margin-bottom: 20px;
  min-height: calc(100vh - 256px); /* Shows the footer if the content is not too long but allows expansion*/
}


.navbar-default {
  background-color: white;
  border: none;
  min-height: 80px;
}

.navbar-brand {
  font-weight: bold;
  height: 80px;
}

.navbar-right {
  padding-top: 15px;
}