@media all and (min-width: 480px) {
  .AdminPanel {
    padding: 10px 0;
  }

  .AdminPanel .btnSmall {
    margin: 0px 0px 0px 15px;
  }
  
  .AdminPanel .frmPanel {
    margin: 10px 10px 0px 10px;
  }
  
  .AdminPanel .fixedtextarea {
    /* white-space: pre; */
    width: 450px;
    height: 200px;
    resize: none;
  }

  .AdminPanel .panelHeading {
    font-weight: 600;
    font-size: 18pt;
    color: navy;
    background-color: papayawhip;
  }

  .mediumDiv {
    max-height: 200px;
  }

  .sortableTableHeader {
    cursor: pointer;
  }

}